import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./Navbar.css";
import { Link } from "react-scroll";
import Modal from "./Modal";
import Logo from "../assets/images/CompressJPEG.online_512x512-image.png"
function Navbar() {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <header>
      
        <a href="/"><img src={Logo} alt ="logo" /></a>{" "}
      
      <nav ref={navRef}>
        <Link to="hero" smooth={true} offset={1} duration={700}>
          Home
        </Link>
        <Link to="abt" smooth={true} offset={-10} duration={700}>
          About
        </Link>
        <Link to="download" smooth={true} offset={-60} duration={700}>
          Download
        </Link>
      <Modal />

        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>
      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </header>
  );
}

export default Navbar;
