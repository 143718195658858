import React, { useState , useRef} from "react";
import "./Modal.css"
import emailjs from '@emailjs/browser';
export default function Modal() {
  const [modal, setModal] = useState(false);

  const form = useRef();
  const toggleModal = () => {
    setModal(!modal);
  };



  if(modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_si5ox7r', 'template_ydxor9d', form.current, 'pwfABMInSoy0fCs28')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset();
  };

  return (
    <>
      <button onClick={toggleModal} className="btn-modal">
        Contact Us
      </button>

      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay" ></div>
          <div className="modal-content" >
            <form ref = {form} onSubmit={sendEmail}>
                <h2>Get in touch 👋</h2> <br /><br />
        <label htmlFor="name">Your Name:</label>
        <input type="text" id="name" name="name" required />

        <label htmlFor="email">Your Email:</label>
        <input type="email" id="email" name="email" required />

        <label htmlFor="message">Your Message:</label>
        <textarea id="message" name="message" rows="5" required></textarea>

        <button type="submit">Submit</button>
      </form>
            <button className="close-modal" onClick={toggleModal}>
              X
            </button>
          </div>
        </div>
      )}
     
    </>
  );
}