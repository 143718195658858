import React, { useEffect } from 'react';
import './about.css';
import Aos from 'aos';
import "aos/dist/aos.css";
import Pie from "../assets/images/pie-removebg-preview.png"
const About = () => {
  useEffect(()=>{
    Aos.init({duration:2000});
  } , [])
    return (
        <div id= "abt" data-aos = "fade-up" className="about-container">
            <div className="about-text">
                <h2>About Us</h2>
                <p>Our application can help any type of specially-abled like Cerebral Palsy, Dyslexia, Blind, Deaf, Voiceless, etc students for their education till class 5th. We are creating the whole curriculum in mindmaps, podcasts, GIFs, animation, small quizzes, and games so the visualization and comprehension power can be developed. Especially for the blind, the whole application will run by speech recognition.</p>
            </div>
            <div className="about-image">
                <img src= {Pie} alt='pie' />
            </div>
        </div>
    );
};

export default About;
