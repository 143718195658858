import React from "react";
import "./downloadApp.css";
import Aos from 'aos';
import "aos/dist/aos.css";
import { useEffect } from "react";
const DownloadApp = () => {

    useEffect(()=>{
        Aos.init({duration:2000});
      } , [])

    return (
        <section  id= "download" className="download-app" data-aos = "fade-up">
            <div   className="container">
                <div className="download-app-content">
                    <h2>Download Our App</h2>
                    <p>
                        Get the latest news from our app. Available on Android.
                    </p>
                    <div className="download-buttons">
                        {/* <a href="https://play.google.com/store/apps/details?id=com.theErrors.theSpecialSchool" className="btn-download" target="_blank">
                            <i className="fab fa-apple"></i> Download on the App Store
                        </a> */}
                        <a href="https://play.google.com/store/apps/details?id=com.theErrors.theSpecialSchool" className="btn-download" target="_blank">
                            <i className="fab fa-google-play" ></i> Get it on Google Play
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DownloadApp;
