import React from "react";
import "./Hero.css";
import { Link } from "react-scroll";
import Bg from "../assets/images/Background photo.png"
function HeroSection() {
  return (
    <div id="hero" className="hero-container">
      <img
        src={Bg}
        alt="Hero Background"
        className="hero-image"
      />
      <div className="hero-content">
        <h1 className="hero-heading">The Special School</h1>
        <p className="hero-subheading">
        Special abilities, Special care
        </p>
        <button
          className="hero-cta"
          onClick={() => console.log("Button clicked!")}
        >
          <Link to="download" smooth={true} offset={-200} duration={700}>
            Download Now
          </Link>
        </button>
      </div>
    </div>
  );
}

export default HeroSection;
