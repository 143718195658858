import React from "react";
import "./testimonial.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Ravi from "../assets/images/ravi.jpg";
import Meera from "../assets/images/meera.jpg";

const Testimonials = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div>
      <div className="testimonials">
        <h2>Raving Reviews from Our Mentors</h2>

        <div className="testimonials-container">
          <div className="testimonial" data-aos="flip-left">
            <img className="test_img" src={Ravi} alt="ravi sir" />
            <p className="testimonial-text">
              Anant and Mujtaba at Euphelity have a great vision of ensuring
              meaningful education at foundational levels for all children with
              special needs. I have been mentoring them since last year and a
              strong passion and thought process of making learning fun for the
              children. We want these children to have same opportunities as
              others. Euphelity provides them the right foundation thru
              their EdTech platform
            </p>
            <div className="testimonial-author">
              <h3>Ravi Purohit </h3>
              <span>Vice President & India Head at ColorTokens Inc</span>
            </div>
          </div>

          <div className="testimonial" data-aos="flip-left">
            <img className="test_img" src={Meera} alt="meera mam" />
            <p className="testimonial-text">
              Students are enjoying using the application. As a teacher, the
              problem which I generally face is creating the image in their
              mind, but this special school app is able to create stories in
              their mind. Anant is one of the oldest students in our center, I
              am very happy that he is solving the problem which he also faced
              in his childhood.
            </p>
            <div className="testimonial-author">
              <h3>Mrs. Meera Naronha</h3>
              <span>
                Director & Principal- Aashayen, The Spastic Center,
                Kanpur, Uttar Pradesh
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
