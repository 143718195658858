import React from "react";
import "./footer.css";
import Aos from 'aos';
import "aos/dist/aos.css";
import { useEffect } from "react";
const Footer = () => {
    useEffect(()=>{
        Aos.init({duration:1500});
      } , [])
    return (
        <footer className="footer" data-aos = "fade-up">
            <div className="container">
                <div className="footer-content">
                    <div className="footer-col">
                        <h3>About Us</h3>
                        <p>
                        Euphelity Pvt Ltd is an umbrella under which different problems are being addressed and their solutions are natured and developed. We are currently focusing on the Project "THE SPECIAL SCHOOL”. 
                        </p>
                    </div>
                    <div className="footer-col">
                        <h3>Contact Us</h3>
                        <ul>
                            <li>Address: Triveni Nagar, Cantt, Kanpur, Uttar Pradesh 208004</li>
                            <li>Phone:+91 88401 43751</li>
                            <li>Email: team@euphelity.in</li>
                        </ul>
                    </div>
                    <div className="footer-col">
                        <h3>Follow Us</h3>
                        <ul>
                            <li>
                                <a href="https://www.linkedin.com/in/anantvaish/" target="_blank">Linkedin</a>
                            </li>
                            <li>
                                <a href="https://twitter.com/AnantVaish" target="_blank">Twitter</a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/anantvaish/" target="_blank">Instagram</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>&copy; 2023 Euphelity Pvt. Ltd. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
