import React from 'react';
import './LogoSlider.css';
import "aos/dist/aos.css";
import { useEffect } from 'react';
import Aos from 'aos';
import Isc from "../assets/images/isc.jpg";
import Mhk from "../assets/images/mahakumbh.jpg"
import Upgis from "../assets/images/upgis.jpg"
const LogoSlider = () => 
{

  useEffect(()=>{
    Aos.init({duration:2000});
  } , [])

  return (
    <div className='lg'>
     <h2>Appreciated By</h2>
    <div data-aos="fade-up" className="logo-slider">
      <img src="https://pbs.twimg.com/profile_images/1492096711300300802/8KvEfkTu_400x400.jpg" alt="Logo 1" />
      <img src="https://www.voiceofsap.org/wp-content/themes/politicalpress-child-theme/images/vosap_logo_thum_image_post.jpg" alt="Logo 2" />
      <img src="https://media-exp1.licdn.com/dms/image/C560BAQGD3qyko2JA1w/company-logo_200_200/0/1660212672785?e=2147483647&v=beta&t=k5eQpltTM9vtaists6Zcf3HyuDvRZX5XU8HMOC2MY3o" alt="Logo 3" />

      <img src="https://indiaeducationdiary.in/wp-content/uploads/2021/05/A2p_rP6_400x400.jpg" alt="Logo 4" />
      <img src={Isc} alt="Logo 5" />
      <img src='https://th.bing.com/th/id/OIP.LEH-F2sv85EBAEil9Ex6wwHaFO?pid=ImgDet&rs=1' alt='logo 6' />
      <img src='https://egirisim.com/wp-content/uploads/2022/04/founders-hub-696x392.jpeg' alt='logo 7' />

      <img src='https://th.bing.com/th/id/OIP.w1j5IXWtDp6r1wvbozs2_AHaF3?pid=ImgDet&rs=1' alt='logo 8' />

      <img src='https://th.bing.com/th/id/OIP.VNsd8UuzB_MbDttDl7NnTAAAAA?pid=ImgDet&w=400&h=300&rs=1' alt='logo 9' />

      <img src='https://studycafe.in/wp-content/uploads/2018/02/msme.png' alt='logo 10' />

      <img src='https://www.jobskar.com/uploads/orgimg/b08a692d66b73f7dda23cae578e789e9.png' alt='logo 11' />

      <img src={Mhk} alt='logo 12' />

      <img src={Upgis} alt='logo 13' />

      <img src='https://th.bing.com/th/id/OIP.YCDOisDu_IWSYwIMrbNLvAAAAA?pid=ImgDet&rs=1' alt='logo 13' />
    </div></div>
  );
};

export default LogoSlider;
