import Navbar from "./components/Navbar";
import HeroSection from "./components/Hero";
import About from "./components/About";
import Footer from "./components/Footer";
import DownloadApp from "./components/DownloadApp";
import Slider from "./components/Slider";
import LogoSlider from "./components/LogoSlider";
import Testimonials from "./components/Testimonial";
import Count from "./components/Count"
function App() {
  return (
    <div className="App">
      <Navbar />
      <HeroSection />
      <About />
      <LogoSlider /><br /><br />
     <Testimonials /><br /> 
      <Slider/><br /><br />
      <DownloadApp />
      <Count /><br /><br /><br />
      <Footer />
    </div>
  );
}

export default App;
