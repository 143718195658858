import CountUp, { useCountUp } from "react-countup";
import "../components/Count.css";
import {IoMdDownload} from "react-icons/io"
import {BsFillPersonLinesFill} from "react-icons/bs"
import { useEffect } from "react";
import Aos from "aos";

const Count = () => {
  useCountUp({
    ref: "counter",
    enableScrollSpy: true,
  });

  useEffect(()=>{
    Aos.init({duration:1500});
  } , [])

  return (
    <>
      <div className="strip" data-aos = "fade-up">
        <div className="f1">
        <BsFillPersonLinesFill size={60} className="bsf"/>
          <h3>Students Enrolled</h3>
          <p className="yo">
            <CountUp start={0} end={500} delay={0} enableScrollSpy/>+
          </p>
        </div>

        <div className="f2"><IoMdDownload  size={60} className="ios"/>
        <h3>Downloads</h3> 
        <p className="yo2">
            <CountUp start={0} end={100} delay={0} enableScrollSpy/>+
          </p>
        </div>
      </div>
    </>
  );
};

export default Count;
